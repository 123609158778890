import moment from "moment";
// import "moment/locale/fr";

const rcvMinAge = 16;
const rcvMaxAge = 100;

const formatInHumanDate = (value, lang) => {
  console.log(lang);
  let date = "";
  if (lang === "en") {
    // moment().locale("en");
    date = moment(value).locale("en").format("MMMM DD, YYYY");
  } else if (lang === "fr") {
    // moment().locale("fr");
    date = moment(value).locale("fr").format("DD MMMM YYYY");
  }
  return date;
};

const formatInHumanDateTime = (value, lang) => {
  let date = "";
  if (lang === "en") {
    moment().local("en");
    date = moment(value).format("MMMM DD, YYYY h:mm a");
  } else if (lang === "fr") {
    moment().local("fr");
    date = moment(value).format("DD MMMM YYYY HH:mm:ss");
  }
  return date;
};

const formatInDateGodd = (value) => {
  let date = moment(value).format("YYYY-MM-DD");
  return date;
};

const formatInDateTime = (value) => {
  let date = moment(value).format("YYYY-MM-DD H:mm:ss");
  return date;
};

function formatAmount(amount) {
  if (typeof amount === "string") amount = Number(amount);
  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function dateIsValid(dateStr, lang) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  let [month, day, year] = dateStr.split("/");
  if (lang === "fr") {
    [day, month, year] = dateStr.split("/");
  }

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
}

function checkAge(rcvYear, min, max) {
  const d = new Date();
  let year = d.getFullYear();
  if (year - rcvYear < min) {
    return false;
  }
  if (year - rcvYear > max) {
    return false;
  }
  return true;
}

function DaysList() {
  var days = [];
  for (let i = 1; i <= 31; i++) {
    var text = i > 9 ? i : "0" + i;
    var obj = { label: text, value: text };
    days.push(obj);
  }
  return days;
}

function MonthList() {
  var months = [
    {
      labelFr: "Janvier",
      labelEn: "January",
      value: "01",
    },
    {
      labelFr: "Février",
      labelEn: "February",
      value: "02",
    },
    {
      labelFr: "Mars",
      labelEn: "March",
      value: "03",
    },
    {
      labelFr: "Avril",
      labelEn: "April",
      value: "04",
    },
    {
      labelFr: "Mai",
      labelEn: "May",
      value: "05",
    },
    {
      labelFr: "Juin",
      labelEn: "June",
      value: "06",
    },
    {
      labelFr: "Juillet",
      labelEn: "July",
      value: "07",
    },

    {
      labelFr: "Aout",
      labelEn: "August",
      value: "08",
    },

    {
      labelFr: "Septembre",
      labelEn: "September",
      value: "09",
    },

    {
      labelFr: "Octobre",
      labelEn: "October",
      value: "10",
    },
    {
      labelFr: "Novembre",
      labelEn: "November",
      value: "11",
    },
    {
      labelFr: "Décembre",
      labelEn: "December",
      value: "12",
    },
  ];
  return months;
}

function YearDobList() {
  var years = [];
  for (let i = 2005; i >= 1901; i--) {
    var text = i;
    var obj = { label: text, value: text };
    years.push(obj);
  }
  return years;
}

function YearExpirationList() {
  var years = [];
  for (let i = 2023; i <= 2050; i++) {
    var text = i;
    var obj = { label: text, value: text };
    years.push(obj);
  }
  return years;
}

function YearIssuingList() {
  var years = [];
  for (let i = 2023; i >= 2002; i--) {
    var text = i;
    var obj = { label: text, value: text };
    years.push(obj);
  }
  return years;
}

function countryStates(CODETABLE, country) 
{
  console.log(CODETABLE.state_province_info.length)
  console.log(country)
  var states = [];
  states = CODETABLE.state_province_info.filter((state) => state.country_code === country)
  return states
}

function formatNumber(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

function formatNumberNoRound(amount, decimalCount = 2) {
  if(amount != undefined && amount != null && amount != "") {
    var num = amount.toString(); //If it's not already a String
    num = num.slice(0, (num.indexOf("."))+decimalCount+1); //With 3 exposing the hundredths place
    num = Number(num); //If you need it back as a Number
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimalCount,
    });
  } else return ""
  
}

const mw_extra_companies = [
  "WAVE",
  "RCTBKLMRT",
  "RCTMSVMRT",
  "RCTSDDMRT",
  "WECHAT",
  "ALIPAY"
];

export {
  formatInHumanDate,
  formatInHumanDateTime,
  formatAmount,
  dateIsValid,
  formatInDateGodd,
  checkAge,
  DaysList,
  MonthList,
  countryStates,
  YearDobList,
  YearExpirationList,
  YearIssuingList,
  formatNumber,
  formatNumberNoRound,
  formatInDateTime,
  mw_extra_companies
};
